import "./TopBar.css";
import useTopBar from "./useTopBar";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { myAccount, logout, Agile, login } from "../../../assets/images";

function TopBar(props) {
  const {
    gotoHome,
    showAccount,
    loginRedirect,
    navigateToMyAccount,
    handleLogout,
  } = useTopBar();
  const { t } = props;
  const user_id = useSelector((state) => state?.userData?.user?._id);
  /**
   *
   * @returns render top bar
   */
  const renderTopBar = () => {
    return (
      <div className="agile flt">
        <div className="main_agile_image flt">
          <a href="https://bookings.hotelsiddharthudupi.com/">
            <img src={Agile} alt="no_image" onClick={gotoHome} />
          </a>
        </div>
        <div className="main_location flt">
          <div className="main_login ">
            {!user_id ? (
              <div className="user_login">
                <img src={login} alt="no_image" />
                <div className="log_in" onClick={loginRedirect}>
                  {t("LOGIN")}
                </div>
              </div>
            ) : (
              <div className="myaccount">
                <button onClick={showAccount} type="button">
                  <img src={login} alt="no_image" />
                </button>
                <ul>
                  <li onClick={navigateToMyAccount}>
                    <img src={myAccount} alt="no_image" />{" "}
                    <span>{t("My_account")}</span>{" "}
                    <i class="far fa-long-arrow-alt-right"></i>
                  </li>
                  <li onClick={handleLogout}>
                    <img src={logout} alt="no_image" />{" "}
                    <span>{t("Logout")}</span>{" "}
                    <i class="far fa-long-arrow-alt-right"></i>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <div className="main_agile flt">{renderTopBar()}</div>;
}
export default withTranslation()(TopBar);
