import TopBar from "../../_common/topbar/TopBar";
import Vegas from "../../_common/Vegas/Vegas";

import "./BookingDetails.css";
import useBookingDetails from "./useBookingDetails";
import { room1_img, note, Loader, sample } from "../../../assets/images";
import { commaValidation } from "../../../utility";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRazorpay } from "../RazorPay/useRazorPay";
import { useLocation } from "react-router";
import { useEffect } from "react";

const BookingDetails = (props) => {
  const location = useLocation();
  const { t } = props;
  let { displayRazorpay } = useRazorpay();
  const {
    name,
    email,
    phone,
    submitted,
    showOtp,
    otp1,
    otp2,
    otp3,
    otp4,
    otp5,
    submit,
    otp1InputRef,
    otp2InputRef,
    otp3InputRef,
    otp4InputRef,
    otp5InputRef,
    bookInfo,
    diffDate,
    userData,
    timeLeft,
    loader,
    pathimg,
    makePayment,
    verifyNumber,
    handleChange,
    sendOtp,
    storeotp,
    otp1valid,
    otp2valid,
    otp3valid,
    otp4valid,
    otp5valid,
    resendOtp,
    verifyMobileOtp,
  } = useBookingDetails(t, location, displayRazorpay);

  const user_id = useSelector((state) => state?.userData?.user?._id);
  const user_name = useSelector((state) => state?.userData?.user?.name);
  const user_email = useSelector((state) => state?.userData?.user?.email);
  const user_phone = useSelector((state) => state?.userData?.user?.phone);

  var extracount = 0;
  var singlecount = 0;
  location?.state?.adultcount.map((data) => {
    if (data == 3) {
      extracount = Number(extracount) + 1;
    }
    if (data == 1) {
      singlecount = Number(singlecount) + 1;
    }
  });

  /**
   * to check otp no
   */
  var validotp1 = otp1valid();
  var validotp2 = otp2valid();
  var validotp3 = otp3valid();
  var validotp4 = otp4valid();
  var validotp5 = otp5valid();

  // useEffect(() => {
  //   let singleOccPrice = Number(
  //     900 *
  //       singlecount *
  //       Number(
  //         diffDate && diffDate !== "1" ? Math.round(diffDate).toFixed(0) : 1
  //       )
  //   ).toFixed(2);
  //   console.log(singleOccPrice, "HHHH");
  // }, [diffDate]);

  const renderTopBar = () => {
    return <TopBar />;
  };
  const renderVegasBar = () => {
    return <Vegas />;
  };

  const renderRoomDetails = () => {
    return (
      <div className="main_room booking_main flt">
        {bookInfo &&
          bookInfo.map((data) => (
            <div className="row">
              <div className="col-sm-5">
                <div className="booking_left flt">
                  <div className="room1_img flt">
                    <span className="room flt">
                      {data?.rooms_type?.[0]?.room_type}
                    </span>
                    <img
                      src={
                        pathimg
                          ? `${process.env.REACT_APP_MEDIA_DOMAIN}${pathimg}`
                          : data?.rooms_type?.[0]?.room_image?.[0]
                          ? `${process.env.REACT_APP_MEDIA_DOMAIN}${data?.rooms_type?.[0]?.room_image?.[0]}`
                          : room1_img
                      }
                      alt="no_image"
                    />
                  </div>
                  <ul className="guest_box flt">
                    <li>
                      <span>{t("Total_Guest")}</span>
                      <b>
                        {data?.adults ? data?.adults : "0"}{" "}
                        {data?.adults === 0 || data?.adults === 1
                          ? t("Adult")
                          : t("Adults")}{" "}
                      </b>
                    </li>
                    <li>
                      <span>{t("Total_Rooms")}</span>
                      <b>{data?.no_of_room}</b>
                    </li>
                    <li>
                      <span>{t("Check_In")}</span>
                      <b>{data?.arrival_date}</b>
                    </li>
                    <li>
                      <span>{t("Check_Out")}</span>
                      <b>{data?.departure_date}</b>
                    </li>
                    <li>
                      <span>{t("Total_Length_of_stay")}</span>
                      <b>
                        {commaValidation(
                          diffDate && diffDate !== "1"
                            ? Math.round(diffDate).toFixed(0)
                            : "1"
                        )}
                      </b>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-7">
                <div className="cost_details flt">
                  <div className="main_room_info flt">
                    <div className="price_details_head flt">
                      Payment Details
                    </div>
                    <ul className="price_details flt">
                      <li>
                        <span>
                          {t("Room_Charges")} ({data?.no_of_room})(
                          {diffDate && diffDate !== "1"
                            ? Math.round(diffDate).toFixed(0)
                            : "1"}
                          ) {data?.rooms_category?.[0]?.room_type} :{" "}
                        </span>
                        {data?.extra_occupancy ===
                        data?.single_occupancy_price ? (
                          <b>
                            ₹{" "}
                            {commaValidation(
                              Number(Number(location?.state?.roomchargeamt))
                            )}
                          </b>
                        ) : (
                          <b>
                            ₹{" "}
                            {commaValidation(
                              Number(
                                Number(location?.state?.roomchargeamt) -
                                  Number(
                                    data?.extra_occupancy *
                                      extracount *
                                      Number(
                                        diffDate && diffDate !== "1"
                                          ? Math.round(diffDate).toFixed(0)
                                          : 1
                                      )
                                  ) +
                                  Number(
                                    data?.single_occupancy_price *
                                      singlecount *
                                      Number(
                                        diffDate && diffDate !== "1"
                                          ? Math.round(diffDate).toFixed(0)
                                          : 1
                                      )
                                  )
                              )
                            )}
                          </b>
                        )}
                      </li>

                      <li>
                        <span>
                          {t("singles")} {"(" + extracount + ")"} :{" "}
                        </span>
                        <b>
                          {data?.extra_occupancy
                            ? `₹ ${commaValidation(
                                Number(
                                  data?.extra_occupancy *
                                    extracount *
                                    Number(
                                      diffDate && diffDate !== "1"
                                        ? Math.round(diffDate).toFixed(0)
                                        : 1
                                    )
                                ).toFixed(2)
                              )}`
                            : 0}
                        </b>
                      </li>
                      {/* singlecount */}
                      <li>
                        <span>
                          {t("singlecount")} {"(" + singlecount + ")"} :{" "}
                        </span>
                        <b>
                          {data?.single_occupancy_price
                            ? `-₹ ${commaValidation(
                                Number(
                                  data?.single_occupancy_price *
                                    singlecount *
                                    Number(
                                      diffDate && diffDate !== "1"
                                        ? Math.round(diffDate).toFixed(0)
                                        : 1
                                    )
                                ).toFixed(2)
                              )}`
                            : 0}
                        </b>
                      </li>
                      {/* } */}
                      <li>
                        <span>{t("Taxes_&_Fees")} : </span>
                        <b>
                          ₹
                          {commaValidation(
                            Number(location?.state?.taxamt).toFixed(2)
                          )}
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div className="grand_total flt">
                    <div className="payable_price flt">
                      <span className="guest_cont flt">
                        {t("Total_Payable_Amount")} :{" "}
                      </span>
                      <span className="guest_info flt">
                        ₹
                        {commaValidation(
                          Number(location?.state?.actualamt).toFixed(2)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };
  const renderBookinInfo = () => {
    return (
      <div className="col-sm-8">
        <div className="booking_info booking_box flt">
          <div className="your_booking flt">{t("Your_Booking_Details")}</div>
          <div className="standard flt">{renderRoomDetails()}</div>
        </div>
      </div>
    );
  };
  const renderPersonalInfo = () => {
    return (
      <div className="col-sm-4">
        <div className="booking_info flt">
          <div className="note flt">
            <img alt="no_img" src={note} />
            <span className="enter_text">{t("Your_Details")}</span>
          </div>
          <div className="input_names flt">
            <div className="input_details flt">
              <label className=" namedata flt">{t("Full_Name")}</label>
              <input type="text" value={user_name} name="name" readOnly />
            </div>

            <div className="input_details flt">
              <label className=" namedata flt">{t("Email_Addres")}</label>
              <input type="text" value={user_email} name="email" readOnly />
            </div>

            <div className="input_details_login flt">
              <label className=" namedata flt">{t("Mobile_Number")}</label>
              <div class="login_ip flt">
                <input type="text" value={user_phone} name="phone" readOnly />
                <span>+91</span>
              </div>
            </div>
          </div>
          <div className="Verfie flt">
            <>
              {loader === "loading" && (
                <div className="main_load ">
                  <img src={Loader} alt="loader" />
                </div>
              )}
              {loader !== "loading" && (
                <button onClick={makePayment}>{t("Pay_Now")}</button>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };
  const renderLoginInfo = () => {
    return (
      <div className="col-sm-4">
        <div className="booking_info flt">
          {(userData === "notSet" || userData === "not-full") && (
            <div className="note flt">
              <img alt="no_img" src={note} />
              <span className="enter_text">{t("Enter_Your_Details")}</span>
            </div>
          )}
          {userData === "notSet" && (
            <>
              <div className="input_names flt">
                <div className="input_details_login flt">
                  <label className=" namedata flt">{t("Mobile_Number")}</label>
                  <div class="login_ip flt">
                    <input
                      type="number"
                      value={user_phone}
                      placeholder={t("Enter_mobile_no")}
                      name="phone"
                      onChange={handleChange}
                    />
                    <span>+91</span>
                  </div>
                </div>
                {submitted && !phone && (
                  <div className="invalid-feedback">
                    {t("Mobile_Number_is_required")}
                  </div>
                )}
              </div>

              <div className="Verfie flt">
                <button onClick={verifyNumber}>{t("Number_verify")}</button>
              </div>
            </>
          )}
          {userData === "full" && (
            <>
              {" "}
              <div className="input_names flt">
                <div className="input_details flt">
                  <label className=" namedata flt">{t("Full_Name")}</label>
                  <input type="text" value={name} name="name" readOnly />
                </div>

                <div className="input_details flt">
                  <label className=" namedata flt">{t("Email_Addres")}</label>
                  <input type="text" value={email} name="email" readOnly />
                </div>

                <div className="input_details_login flt">
                  <label className=" namedata flt">{t("Mobile_Number")}</label>
                  <div class="login_ip flt">
                    <input type="text" value={phone} name="phone" readOnly />
                    <span>+91</span>
                  </div>
                </div>
              </div>
              <div className="Verfie flt">
                {loader === "loading" && (
                  <div className="main_load ">
                    <img src={Loader} alt="loader" />
                  </div>
                )}
                {loader !== "loading" && (
                  <button onClick={makePayment}>{t("Pay_Now")}</button>
                )}
              </div>
            </>
          )}
          {userData === "not-full" && (
            <>
              <div className="input_names flt">
                <div className="input_details flt">
                  <label className=" namedata flt">{t("Full_Name")}</label>
                  <input
                    type="text"
                    value={name}
                    name="name"
                    placeholder={t("Enter_fullname")}
                    onChange={handleChange}
                  />
                </div>
                {submitted && !name && (
                  <div className="invalid-feedback">
                    {t("Name_is_required")}
                  </div>
                )}
                <div className="input_details flt">
                  <label className=" namedata flt">{t("Email_Addres")}</label>
                  <input
                    type="text"
                    value={email}
                    name="email"
                    placeholder={t("Enter_email")}
                    onChange={handleChange}
                  />
                </div>
                {submitted && !email && (
                  <div className="invalid-feedback">
                    {t("Email_is_required")}
                  </div>
                )}
                <div className="input_details_login flt">
                  <label className=" namedata flt">{t("Mobile_Number")}</label>
                  <div class="login_ip flt">
                    <input type="text" value={phone} name="phone" readOnly />
                    <span>+91</span>
                  </div>
                </div>
              </div>
              {showOtp === false && (
                <div className="note flt">
                  <button onClick={sendOtp}>{t("Send_OTP")}</button>
                </div>
              )}
              {showOtp === true && (
                <>
                  {loader === "loading" && (
                    <div className="main_load ">
                      <img src={Loader} alt="loader" />
                    </div>
                  )}
                  {loader !== "loading" && (
                    <>
                      <div className="input_otp_details flt">
                        <label className=" namedata1 flt">
                          <div className="guest_cont">
                            {t("Enter_5_Digits")}
                          </div>
                          <div className="guest_infos">
                            <span className="timer">
                              {timeLeft ? "" + timeLeft : ""}
                            </span>{" "}
                            {timeLeft === null ? (
                              <span className="resend" onClick={resendOtp}>
                                {t("Resend_OTP")}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </label>
                        <div className="otp_data flt">
                          <input
                            type="text"
                            placeholder=""
                            name="otp1"
                            className={submit && validotp1 ? "is-invalid" : ""}
                            value={otp1}
                            onChange={storeotp}
                            ref={otp1InputRef}
                          />
                          <input
                            type="text"
                            placeholder=""
                            name="otp2"
                            className={submit && validotp2 ? "is-invalid" : ""}
                            value={otp2}
                            onChange={storeotp}
                            ref={otp2InputRef}
                          />
                          <input
                            type="text"
                            placeholder=""
                            name="otp3"
                            className={submit && validotp3 ? "is-invalid" : ""}
                            value={otp3}
                            onChange={storeotp}
                            ref={otp3InputRef}
                          />
                          <input
                            type="text"
                            placeholder=""
                            name="otp4"
                            className={submit && validotp4 ? "is-invalid" : ""}
                            value={otp4}
                            onChange={storeotp}
                            ref={otp4InputRef}
                          />
                          <input
                            type="text"
                            placeholder=""
                            name="otp5"
                            className={submit && validotp5 ? "is-invalid" : ""}
                            value={otp5}
                            onChange={storeotp}
                            ref={otp5InputRef}
                          />
                        </div>
                      </div>
                      <div className="Verfie flt">
                        <button onClick={verifyMobileOtp}>
                          {t("Verify_and_Continue")}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderBookingscreen = () => {
    return (
      <div className="main_booking flt">
        <div className="container-fluid flt">
          <div className="row">
            {renderBookinInfo()}

            {user_id === undefined ? renderLoginInfo() : renderPersonalInfo()}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {renderTopBar()}
      {renderVegasBar()}
      {renderBookingscreen()}
    </>
  );
};
export default withTranslation()(BookingDetails);
